<template>
  <div class="rules">
    <SectionHeader></SectionHeader>
    <div class="section">
      <div class="container is-relative fix-position">
        <div class="columns">
          <div class="column is-full mb-6">
            <div class="content is-medium" v-if="!getCalendarMode()">
              <h1 class="has-text-centered">Usein kysytyt kysymykset</h1>
              <h3>Kuinka paljon palvelu maksaa?</h3>
              <p>Palvelun hinta on riippuvainen valitsemastasi tuotteesta. Ajantasaisen hinnaston näe <router-link :to="{ name: 'pricing',}">täältä</router-link>.</p>
              <p>
                Hinnat sisältävät alv. 14 %. Hintaan kuuluu valitsemasi tuote itse suunnitellussa pakkauksessa.
                Sen lisäksi tilaukselle lasketaan toimituskulut valitsemasi toimitustavan mukaan.
              </p>
              <p>
                Psst. Palvelumme on mukana tukemassa seurojen ja yhdistysten toimintaa. Kun tilaat tuotteita varainkeruuta
                varten, käytä tilatessa koodia: VARAINHANKINTA ja saat 15 % alennuksen tilauksesta.
              </p>

              <h3>Voiko Tuotteita käyttää varainkeruuseen?</h3>
              <p>
                Personoitu tuote sopii erinomaisesti varainhankintaan! Palvelumme on mukana tukemassa seurojen ja
                yhdistysten toimintaa. Kun tilaat tuotteita varainkeruuta varten, käytä tilatessa koodia: VARAINHANKINTA
                ja saat 15 % alennuksen tilauksesta. Hinta lasketaan tilatun määrän ja valitun toimitustavan mukaan.
                Hinnat sisältävät alv 14 %. Päätätte itse, millä hinnalla myytte tuotteet tukijoille. Mitä enemmän
                tilaat, sitä edullisemmaksi pakkaus tulee ja sitä enemmän keräätte tukea toiminnallenne!
              </p>

              <h3>Montako erilaista pakkausta voin tilata samalla tilauksella?</h3>
              <p>
                Minimitilausmäärä on riippuvainen tuotteesta. Oma Domino -pakkauksen minimitilaus on 24 Oma Domino
                -pakkausta. Karl Fazer -konvehtipakkauksen minimitilausmäärä on 20 pakkausta. Custom Candy box
                -karkkipaketin, Karl Fazer -suklaalevyn ja Marianne-paketin minimitilausmäärä on 50. Parasta on,
                että niistä jokainen voi olla omanlainen. Karkkipaketin, suklaalevyn ja Marianne-paketin tekstit
                on mahdollista toimittaa myös excel-tiedostona, jolloin tekstit on helpompi syöttää eri paketeille.
                Domino-pakettiin liittyy kuvan käsittelyä haluamallanne tavalla, joten kaikki paketit on muokattava
                palvelussa.
              </p>

              <h3>Mitkä maksutavat ovat käytössä?</h3>
              <p>
                Yritystilauksissa maksutapoina on lasku sekä yleisimmät verkkomaksutavat, kuten pankkimaksu ja
                luottokorttimaksu. Huomioithan, että laskulla maksaminen edellyttää, että ilmoitat tilaustiedoissa
                yrityksen tai yhdistyksen y-tunnuksen. Maksuehto on laskulla 14 päivää netto. Lasku on maksutapana
                vain yritystilauksissa. Kun valitset maksun verkossa, maksun välittäjänä toimii Paytrail. Sinut
                ohjataan Paytrail-maksuseinälle, mistä sinut ohjataan onnistuneen maksusuorituksen jälkeen takaisin
                Custom Fazer -palveluun.
              </p>
              <p>
                Kuluttajatilauksissa lasku ei ole maksutapana, vaan maksutapana on vain yleisimmät verkkomaksutavat,
                kuten pankkimaksu ja luottokorttimaksu. Kun valitset maksun verkossa, maksun välittäjänä toimii Paytrail.
                Sinut ohjataan Paytrail-maksuseinälle, mistä sinut ohjataan onnistuneen maksusuorituksen jälkeen takaisin
                Custom Fazer -palveluun.
              </p>

              <h3>Mikä on toimitusaika?</h3>
              <p>
                Tilaukset käsitellään ja painetaan viimeistään 2-3 viikon kuluessa tilauksen vahvistamista seuraavana
                arkipäivänä, jonka jälkeen tilatut tuotteet luovutetaan kuljetusyhtiön toimitettavaksi.
              </p>

              <h3>Minne ja miten tilaus toimitetaan?</h3>
              <p>
                Palvelussa tehdyt tilaukset toimitetaan asiakkaan antamaan toimitusosoitteeseen (toimitusalue on Manner-Suomi).
                Ulkomaan (EU-alue) toimitukset ja useaan toimipaikkaan toimitettavat toimitukset käsitellään tapauskohtaisesti.
                Emme toimita Custom Fazer -palvelun tuotteita Kreikkaan, Romaniaan, Bulgariaan, Kanarian Saarille tai Ahvenanmaalle
              </p>
              <p>Kuljetusyhtiöt: Posti Oyj, Deutsche Post, Schenker ja Kaukokiito</p>

              <h3>Miten voin seurata tilaustani?</h3>
              <p>Saat tiedon sähköpostiisi, kun tilauksesi lähtee ja tässä viestissä on mukana rahdin seurantalinkki</p>

              <h3>Miten saan parhaan mahdollisen painojäljen?</h3>
              <p>
                Kun tilaat Oma Domino -tuotteen, voit itse lisätä tuotteeseen kuvan. Valitse selkeä terävä kuva ja kuvan
                tiedoston koko olisi hyvä olla yli 2MT , mutta alle 25MT
              </p>
              <p>
                Tulostustarkkuus riippuu palveluun ladattavan kuvan laadusta. Tulostustarkkuus numeroina: Image resolution
                812 dpi at 8 bit, addressability: 2438 x 2438 dpi HDI (High Definition Imaging)
              </p>

              <h3>Kenen kuvia voin käyttää?</h3>
              <p>
                Tilaajan vastuulla on, että hänellä on lupa käyttää palveluun ladattua kuvaa. Kysythän aina luvan kuvan
                käyttöön sekä kuvan ottajalta että kuvassa tunnistettavissa olevilta henkilöiltä. Pyydä lupa myös silloin,
                kun käytettävä kuva on piirustus tai muu taideteos.
              </p>

              <h3>Kuinka pitkä teksti voi olla?</h3>
              <p>
                Tekstien pituus riippuu tuotteesta. Oma Domino -paketissa teksti voi olla 120 merkkiä. Karl Fazer
                -konvehtipaketissa, suklaalevyssä, karkkipaketissa ja Marianne -paketissa etupuolen teksti voi olla 16
                merkkiä, takapuolen teksti 250 merkkiä ja allekirjoitus 30 merkkiä. Määrät sisältävät erikoismerkit,
                rivinvaihdot ja välilyönnit.
              </p>

              <h3>Voinko kirjoittaa kääreeseen emojia / hymiöitä tai erikoismerkkejä?</h3>
              <p>
                Valitettavasti kääreen fontti ei tue emoticoneja, emojia tai symboleita. Mutta voit toki käyttää mielesi
                mukaan ”tekstihymiöitä” kuten :-) tai >3. Myös "normaalit erikoismerkit" kuten ! @ $ | &gt; &lt; * # toimivat.
              </p>

              <h3>Voivatko kuvat ja tekstit olla mitä tahansa?</h3>
              <p>
                Palvelumme on tarkoitettu ilahduttamaan, kiittämään, yllättämään positiivisesti ja kannustamaan. Emme
                hyväksy palvelun kautta lähetettävissä kuvissa tai teksteissä kiroilua, rasistista kielenkäyttöä tai
                muuta hyvän tavan vastaista tai Fazerin eettisten periaatteiden vastaista kirjoitusta. Lue Fazerin
                eettiset periaatteet <a href="https://www.fazergroup.com/fi/vastuullisuus/" target="_blank">täältä</a>.
              </p>

              <p>
                Fazerilla on oikeus estää palvelun sääntöjen vastaisen viestin lähettäminen. Fazerilla on oikeus
                perustellusta syystä olla hyväksymättä tilausta tai osaa siitä tilauksen vastaanottovahvistuksen
                lähettämisestä huolimatta. <router-link :to="{ name: 'rules',}">Tutustu palvelumme sopimus- ja toimitusehtoihin</router-link>
              </p>

              <h3>Mistä tiedän, miltä valmis tuote näyttää?</h3>

              <p>
                Voit esikatsella valmista tuotetta suunnitteluvaiheessa löytyvän 3D-esikatselun avulla.
                3D-esikatselu löytyy sivun yläosasta.
              </p>

              <h3>Mikä on tuotteiden säilyvyys?</h3>

              <p>Tuotteilla on aina vähintään 3 kk jäljellä parasta ennen -päivään toimitushetkestä.</p>

              <h3>Mistä tuotteet on tehty (allergeenit)?</h3>

              <p>Domino:</p>

              <p>
                Domino on Suomen rakastetuin keksi, jonka yhteinen taival suomalaisten kanssa on jatkunut jo vuodesta
                1953. Aito ja alkuperäinen Domino Original on täytekeksin klassikkomaku, jossa tumma kaakaokeksi ja
                valkoinen vaniljatäyte muodostavat ainutlaatuisen makuelämyksen. Mikä parasta, keksi leivotaan ilman
                palmuöljyä ja siihen on käytetty 100 % vastuullisesti tuotettua kaakaota. Domino maistuu juhlissa ja
                kahvipöydässä. Keksi sopii myös vegaaneille.
              </p>

              <p>
                Ainesosat: sokeri, VEHNÄjauho, rypsiöljy, kokonaan kovetettu kookosrasva, vähärasvainen kaakaojauhe,
                VEHNÄtärkkelys, suola, emulgointiaine (SOIJAlesitiini), VEHNÄkuitu, nostatusaineet (E500, E450), aromit.
                SAATTAA SISÄLTÄÄ MAITOA, KANANMUNAA, HASSELPÄHKINÄÄ JA MUUTA GLUTEENIA SISÄLTÄVÄÄ VILJAA.
              </p>

              <p>Karl Fazer -maitosuklaakonvehdit:</p>

              <p>
                Suomen rakastetuin suklaa, jonka ainutlaatuisen täyteläisen maun ovat taanneet parhaat raaka-aineet jo
                vuodesta 1922 saakka. Tunnistat tämän aidosta maidosta valmistetun maitosuklaan maun lisäksi myös
                sinisestä kääreestä, jonka kultainen Karl Fazer -allekirjoitus toimii laadun takeena. Mikä parasta,
                kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Anna
                parhaisiin hetkiin sopiva suklaarasia lahjaksi tai nautiskele ystävien kanssa.
              </p>
              <p>
                Ainesosat: MAITO, sokeri, kaakaovoi, kaakaomassa, emulgointiaine (SOIJAlesitiini), suola, aromi
                (luontainen vanilja). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa
                vähintään 30% kaakaota.
              </p>

              <p>Marianne:</p>

              <p>
                Mariannen raikas ja rapea piparminttukuori kietoo sisäänsä runsaasti herkullista täytettä. Punavalkoisista
                raidoistaan tunnetun Marianne Originalin sisältä paljastuu suussa sulavaa tummaa suklaata. Mikä parasta,
                kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Mariannet
                ovat gluteenittomia, vegaanisia ja vähälaktoosia. Nauti Marianne pussi yksin tai kutsu ystävät ihanaan
                hetkeen.
              </p>
              <p>
                Ainesosat: sokeri, glukoosisiirappi, kaakaomassa, kasvirasva (palmu, shea), kaakaovoi, aromit,
                emulgointiaine (SOIJAlesitiini). SAATTAA SISÄLTÄÄ MAITOA. SUKLAA SISÄLTÄÄ KAAKAOVOIN LISÄKSI MUITA
                KASVIRASVOJA.
              </p>

              <p>Karl Fazer –suklaalevy:</p>

              <p>
                Fazerin sininen on Suomen rakastetuin suklaa, jonka ainutlaatuisen täyteläisen maun ovat taanneet parhaat
                raaka-aineet jo vuodesta 1922 saakka. Tunnistat tämän aidosta maidosta valmistetun suklaalevyn sinisestä
                kääreestä, jonka kultainen Karl Fazer -allekirjoitus toimii laadun takeena. Mikä parasta, kaikki Fazerin
                herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Karl Fazerin Sininen
                suklaalevy kruunaa hetken kuin hetken ylellisellä maullaan
              </p>

              <p>
                Ainesosat: MAITO, sokeri, kaakaovoi, kaakaomassa, emulgointiaine (SOIJAlesitiini), suola, aromi
                (luontainen vanilja). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa
                vähintään 30% kaakaota.
              </p>

              <p>Karl Fazer Hasselpähkinä -suklaalevy 200g:</p>

              <p>
                Fazerin herkullinen suklaalevy on hienostunut yhdistelmä kokonaisia hasselpähkinöitä ja aidosta maidosta
                valmistettua pehmeää maitosuklaata. Suomen rakastetuin suklaa, jonka ainutlaatuisen täyteläisen maun ovat
                taanneet parhaat raaka-aineet jo vuodesta 1922 saakka. Kaikki Fazerin herkulliset suklaat on valmistettu
                100 % vastuullisesti tuotetusta kaakaosta. Jaa suklaalevy ystävien kanssa tai anna itsellesi herkullinen
                hetki.
              </p>

              <p>
                Ainesosat: MAITO, sokeri, kaakaovoi, HASSELPÄHKINÄT, kaakaomassa, emulgointiaine (SOIJAlesitiini), suola,
                aromi (luontainen vanilja). SAATTAA SISÄLTÄÄ MUUTA PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA.
                Maitosuklaassa vähintään 30% kaakaota.
              </p>

              <p>Karl Fazer Lontoon rae -suklaalevy 180g:</p>

              <p>
                Lempeät Lontoo Rae lakritsirakeet uppoutuvat pahmeään Karl Fazer maitosuklaaseen luoden ihanan
                makuyhdistelmän, jota on vaikea vastustaa! Fazer on suomen rakastetuin suklaa, jonka ainutlaatuisen
                täyteläisen maun ovat taanneet parhaat raaka-aineet jo vuodesta 1922 saakka. Kaikki Fazerin herkulliset
                suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Jaa suklaalevy ystävien kanssa tai anna
                itsellesi herkullinen hetki.
              </p>

             <p>
               Ainesosat: sokeri, kaakaovoi, täysMAITOjauhe, kaakaomassa, kirnuMAITOjauhe, glukoosisiirappi, kokonaan
               kovetettu kasvirasva (kookos), lakritsiuute, perunatärkkelys, emulgointiaine (lesitiini mm. SOIJA), aromit,
               pintakäsittelyaineet (E901, E904), ammoniumkloridi (salmiakki), auringonkukkaöljy, väri (E153), suola.
               SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa vähintään 30% kaakaota.
             </p>

             <p>Karl Fazer Rouhea Keksi -suklaalevy 180g:</p>

             <p>
               Suomen rakastetuin suklaa, jonka ainutlaatuisen täyteläisen maun ovat taanneet parhaat raaka-aineet jo
               vuodesta 1922 saakka. Herkullinen levyllinen aidosta maidosta valmistettua pehmeää maitosuklaata
               yhdistettynä rouhean rouskuvaa keksiä. Mikä parasta, kaikki Fazerin herkulliset suklaat on valmistettu
               100 % vastuullisesti tuotetusta kaakaosta. Jaa levy ystävien kanssa tai anna itsellesi herkullinen hetki.
             </p>

            <p>
              Ainesosat: MAITO, sokeri, kaakaovoi, kaakaomassa, VEHNÄjauho, emulgointiaine (SOIJAlesitiini), palmuöljy,
              VEHNÄtärkkelys, suola, luontainen aromi (vanilja). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA MUUTA GLUTEENIA
              SISÄLTÄVÄÄ VILJAA. Maitosuklaassa vähintään 30% kaakaota.
            </p>

            <p>Dumle 120 g -karkkipussi:</p>

            <p>
              Dumle on iloinen klassikko, joka ei aikuistu koskaan. Maitosuklaalla kuorrutettu toffeekarkki on maistuvan
              riemukas seuralainen, joka saa aina hyvälle tuulelle. Nauti karkkipussista Dumle tai pari tuomaan pilkettä
              silmäkulmaan! Valmistettu 100 % vastuullisesti tuotetusta kaakaosta.
            </p>

            <p>
              Ainesosat: glukoosisiirappi, sokeri, kasvirasva (palmu), täysMAITOjauhe, kaakaovoi, herajauhe (MAIDOSTA),
              kaakaomassa, rasvaton MAITOjauhe, suola, emulgointiaine (SOIJAlesitiini), aromit (karamelli, vanilliini).
              SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa vähintään 30% kaakaota.
            </p>

            <p>Tutti Frutti Passion 180 g -karkkipussi:</p>

            <p>
              Hedelmäinen Tutti Frutti tuo iloa ja aurinkoa kaikille. Tutti Frutti Passion karkkipussi on jännittävä
              sekoitus trooppisia makuja, jotka frutittavat makuhermojasi. Psst! Tutti Frutti Passion on gelatiiniton,
              vegaaninen ja laktoositon. Karkit on valmistettu luotaisilla väreillä ja ne saavat raikkaan makunsa
              luontaisista aromeista. Nauti yksin tai tee kavereittesi päivästä parempi!
            </p>

            <p>
              Ainesosat: sokeri, glukoosisiirappi, maissitärkkelys, happamuudensäätöaineet (E270, E325), luontaiset aromit,
              värjäävät elintarvikkeet (mustaporkkana-, saflori- ja spirulinatiiviste ja paprikaöljyuute),
              pintakäsittelyaine (E903), väri (antosyaaniuute). SAATTAA SISÄLTÄÄ
            </p>

            <p>Fazer Oma kalenterin konvehdit</p>

            <p>Karl Fazer Maitosuklaa</p>

              <p>
                Suomen rakastetuin suklaa, jonka ainutlaatuisen täyteläisen maun ovat taanneet parhaat raaka-aineet jo
                vuodesta 1922 saakka. Tunnistat tämän aidosta maidosta valmistetun kansallisaarteemme maun lisäksi myös
                sinisestä kääreestä, jonka kultainen Karl Fazer -allekirjoitus toimii laadun takeena. Mikä parasta,
                kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Nauti
                konvehti kahvin seurana tai hiljenny hetkeksi nautiskelemaan.
              </p>

              <p>
                Ainesosat: MAITO, sokeri, kaakaovoi, kaakaomassa, emulgointiaine (SOIJAlesitiini), suola, luontainen
                aromi (vanilja). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa
                vähintään 30% kaakaota.
              </p>

              <p>Geisha</p>

              <p>
                Geishan pehmeä maitosuklaa kätkee sisäänsä hasselpähkinänougat-täytteen. Geisha-konvehtien ylellisen
                paksu maitosuklaakuori täydentää rapeaa täytettä. Mikä parasta, tämä herkullinen Geisha-suklaa on
                valmistettu 100 % vastuullisesti tuotetusta kaakaosta ja ilman palmyöljyä. Rauhoita päiväsi hetkeksi
                ja nauti pari Geisha suklaakonvehtia tai tarjoa rakkaillesi hetki yhteistä aikaa niiden parissa.
              </p>

              <p>
                Ainesosat: sokeri, MAITO, kaakaovoi, HASSELPÄHKINÄT (10%), kaakaomassa, täysMAITOjauhe, kirnuMAITOjauhe,
                VEHNÄjauho, maissitärkkelys, emulgointiaine (SOIJAlesitiini), auringonkukkaöljy, suola, aromit. SAATTAA
                SISÄLTÄÄ MUUTA PÄHKINÄÄ, MANTELIA JA MUUTA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa vähintään 30%
                kaakaota.
              </p>

              <p>Dumle</p>

              <p>
                Dumle on iloinen klassikko, joka ei aikuistu koskaan. Maitosuklaalla kuorrutettu toffeekaramelli on
                maistuvan riemukas seuralainen, joka saa aina hyvälle tuulelle. Nakkaa suuhun konvehti tai pari!
                Valmistettu 100 % vastuullisesti tuotetusta kaakaosta.
              </p>

              <p>
                Ainesosat: glukoosisiirappi, sokeri, kasvirasva (palmu), täysMAITOjauhe, kaakaovoi, herajauhe
                (MAIDOSTA), kaakaomassa, MAITO, rasvaton MAITOjauhe, suola, emulgointiaine (lesitiini mm. SOIJA),
                aromit. SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA VEHNÄÄ. Maitosuklaassa vähintään 30% kaakaota.
              </p>

              <p>
                Fazermint
              </p>

              <p>
                Fazermint on ajattoman elegantti minttukonvehti. Suussa sulavan pehmeä tumma suklaa kietoo sisäänsä
                aidolla piparminttuöljyllä maustettua pehmeää täytettä, joka tarjoaa ylellisyyttä jokaiseen päivään.
                Mikä parasta, kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta
                kaakaosta.
              </p>

              <p>
                Ainesosat: sokeri, kaakaomassa, glukoosisiirappi, kaakaovoi, MAITOrasva, emulgointiaine (SOIJAlesitiini),
                piparminttuöljy, vanilliini, kosteudensäilyttäjä (invertaasi). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA
                GLUTEENIA SISÄLTÄVÄÄ VILJAA. Tummassa suklaassa vähintään 47% kaakaota.
              </p>

              <p>Julia</p>

              <p>
                Julia on tyylikäs klassikko, joka on maistunut suomalaisille jo 1950-luvulta saakka. Julia-kohvehdissa
                yhdistyy vastustamattomalla tavalla hienostunut tumma suklaa ja samettinen hedelmänmakuinen marmeladi.
                Mikä parasta, kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta
                kaakaosta. Tarjoa ystävillesi elegantti herkutteluhetki Julian seurassa tai nauti itse konvehti tai
                pari.
              </p>

              <p>
                Ainesosat: sokeri, glukoosisiirappi, vesi, kaakaomassa, kaakaovoi, hyytelöimisaine (E440),
                happamuudensäätöaineet (E330, E331, E337), MAITOrasva, emulgointiaine (SOIJAlesitiini), säilöntäaine
                (E202), aromit. SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Tummassa suklaassa
                vähintään 47% kaakaota.
              </p>

              <p>
                Vihreitä kuulia suklaakonvehti
              </p>

              <p>
                Kun vihreä klassikkomarmeladi sai ylleen täyteläisen Fazerin tummasuklaakuorrutuksen, syntyi uusi
                suosikkikonvehti, jota kaikki haluavat maistaa. Vastustamattoman ihanat Fazer Vihreitä kuulia
                -suklaakonvehdit maistuvat hurmaavasti raikkaalle päärynälle. Mikä parasta, kaikki Fazerin herkulliset
                suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Konvehdit sopivat yhteiseen joulun
                tunnelmointiin tai lahjaksi juhlahetkiin.
              </p>

              <p>
                Ainesosat: sokeri, glukoosisiirappi, vesi, kaakaomassa, kaakaovoi, hyytelöimisaine (E440),
                happamuudensäätöaineet (E330, E331, E337), MAITOrasva, emulgointiaine (SOIJAlesitiini), säilöntäaine
                (E202), aromit, väri (E141). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA.
                Tummassa suklaassa vähintään 47% kaakaota.
              </p>

              <button
                class="upload-button button is-primary mt-6"
                @click="$router.push({name: 'Home'})"
              >
                <span>Takaisin</span>
              </button>
            </div>
            <div class="content is-medium" v-else>
              <h1 class="has-text-centered">Usein kysytyt kysymykset</h1>
              <h3>Kuinka paljon palvelu maksaa?</h3>
              <p>
                Kalenterin hinta on 25,90 €/ kpl . Hinta sisältää alv 14 %. Hintaan kuulu itse suunnittelemasi kalenteri,
                sekä sisälle tulevat makeiset. Kalenterissa on yhteensä 25 konvehtia. Sen lisäksi tilaukselle lasketaan
                toimituskulut 4,90 €. Tuotteet toimitetaan postipakettina Manner-Suomeen.
              </p>

              <h3>Montako erilaista pakkausta voin tilata samalla tilauksella?</h3>
              <p>
                Minimitilausmäärä on yksi kalenteri ja voit yhdellä tilauksella tilata enintään 20 kalenteria.
                Jokaisessa kalenterissa voi olla eri kuva. Yli 20 kappaleen tilaukset voit tehdä
                <a href="https://custom.fazer.com" target="_blank">Custom Fazer -palvelussa</a>,
                joka on ensisijaisesti tarkoitettu yrityksille. Tulemme julkaisemaan vastaavan kalenterituotteen myös
                yrityspalvelussamme.
              </p>

              <h3>Mitkä maksutavat ovat käytössä?</h3>
              <p>
                Maksun välittäjänä toimii Paytrail. Maksutapana on vain yleisimmät verkkomaksutavat, kuten pankkimaksu
                ja luottokorttimaksu. Kun valitset maksun verkossa, maksun välittäjänä toimii Paytrail. Sinut ohjataan
                Paytrail-maksuseinälle, mistä sinut ohjataan onnistuneen maksusuorituksen jälkeen takaisin Fazer Oma
                kalenteri -palveluun.
              </p>

              <h3>Voinko tilata samalla muita Fazerin tuotteita?</h3>

              <p>
                Fazer Oma kalenteri -palvelu on itsenäinen palvelu eikä samalla voi tilata muita tuotteita. Mikäli
                haluat tilata muita Fazerin tuotteita, voit asioida <a href="https://fi.fazer.com/" target="_blank">Fazer Store -kuluttajaverkkokaupassamme.</a>
              </p>

              <h3>Voinko hyödyntää Fazerin alennuskoodeja palvelussa?</h3>

              <p>
                Fazer Oma kalenteri -palvelu on erillinen palvelu, jolla on käytössä omat tarjoukset ja kampanjakoodit.
                Mahdolliset tarjoukset tai kampanjakoodit muista Fazerin palveluista ole käytettävissä palvelussa,
                ellei toisin erikseen mainita.
              </p>

              <h3>Voinko tehdä yritystilauksen ja maksaa laskulla?</h3>

              <p>
                Fazer Oma kalenteri -palvelussa ei ole laskua maksutapana. Mikäli haluat tehdä tilauksen isommasta
                määrästä ja maksaa laskulla, voit tehdä sen yrityksille suunnatussa
                <a href="https://custom.fazer.com" target="_blank">Custom Fazer -palvelussa</a>, joka on ensisijaisesti
                tarkoitettu yrityksille. Tulemme julkaisemaan vastaavan kalenterituotteen myös yrityspalvelussamme.
              </p>

              <h3>Mikä on toimitusaika?</h3>
              <p>
                Tilaukset käsitellään ja painetaan viimeistään 2-3 viikon kuluessa tilauksen vahvistamista seuraavana
                arkipäivänä, jonka jälkeen tilatut tuotteet luovutetaan kuljetusyhtiön toimitettavaksi.
              </p>

              <h3>Minne ja miten tilaus toimitetaan?</h3>
              <p>
                Palvelussa tehdyt tilaukset toimitetaan postipakettina asiakkaan antamaan toimitusosoitteeseen
                (toimitusalue on Manner-Suomi). Tuotteet ovat noudettavissa lähimmästä noutopisteestä.
              </p>

              <h3>Miten voin seurata tilaustani?</h3>
              <p>Saat tiedon sähköpostiisi, kun tilauksesi lähtee ja tässä viestissä on mukana rahdin seurantalinkki</p>

              <h3>Miten saan parhaan mahdollisen painojäljen?</h3>
              <p>
                Kun tilaat Fazer Oma kalenteri -tuotteen, voit itse lisätä tuotteeseen kuvan. Valitse selkeä terävä
                kuva ja kuvan tiedoston koko olisi hyvä olla yli 2MT , mutta alle 25MT.
              </p>
              <p>
                Tulostustarkkuus riippuu palveluun ladattavan kuvan laadusta. Kalenterin koko on 268mm x 388mm, joten
                suositeltava kuvan koko on 3164px x 4582px @300dpi resoluutio. Voit kuitenkin kokeilla, miltä kuva
                näyttää itse palvelussa. Mikäli kuva on liian pieni, niin se voi näyttää epäselvältä.
              </p>

              <h3>Kenen kuvia voin käyttää?</h3>
              <p>
                Tilaajan vastuulla on, että hänellä on lupa käyttää palveluun ladattua kuvaa. Kysythän aina luvan kuvan
                käyttöön sekä kuvan ottajalta että kuvassa tunnistettavissa olevilta henkilöiltä. Pyydä lupa myös silloin,
                kun käytettävä kuva on piirustus tai muu taideteos.
              </p>

              <h3>Voivatko kuvat ja tekstit olla mitä tahansa?</h3>
              <p>
                Palvelumme on tarkoitettu ilahduttamaan, kiittämään, yllättämään positiivisesti ja kannustamaan. Emme
                hyväksy palvelun kautta lähetettävissä kuvissa tai teksteissä kiroilua, rasistista kielenkäyttöä tai
                muuta hyvän tavan vastaista tai Fazerin eettisten periaatteiden vastaista kirjoitusta. Lue Fazerin
                eettiset periaatteet <a href="https://www.fazergroup.com/fi/vastuullisuus2/" target="_blank">täältä</a>
                Fazerilla on oikeus estää palvelun sääntöjen vastaisen viestin lähettäminen. Fazerilla on oikeus
                perustellusta syystä olla hyväksymättä tilausta tai osaa siitä tilauksen vastaanottovahvistuksen lähettämisestä
                huolimatta. <router-link :to="{ name: 'rules' }" target="_blank">Tutustu palvelumme sopimus- ja toimitusehtoihin</router-link>.
              </p>

              <h3>Mistä tiedän, miltä valmis tuote näyttää?</h3>

              <p>
                Kun lataat kuvan palveluun, näet esikatselukuvan kalenterista. Voit liikutella kuvan oikeaan kohtaan,
                sekä lähentää tai loitontaa haluamallasi tavalla. Voit halutessasi lisätä kalenterin reunoille jouluisen
                kehyksen.
              </p>

              <h3>Mikä on tuotteiden säilyvyys?</h3>

              <p>Tuotteilla on aina vähintään 3 kk jäljellä parasta ennen -päivään toimitushetkestä.</p>

              <h3>Mistä tuotteet on tehty (allergeenit)?</h3>

              <p>Karl Fazer Maitosuklaa</p>

              <p>
                Suomen rakastetuin suklaa, jonka ainutlaatuisen täyteläisen maun ovat taanneet parhaat raaka-aineet jo
                vuodesta 1922 saakka. Tunnistat tämän aidosta maidosta valmistetun kansallisaarteemme maun lisäksi myös
                sinisestä kääreestä, jonka kultainen Karl Fazer -allekirjoitus toimii laadun takeena. Mikä parasta,
                kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta.
                Nauti konvehti kahvin seurana tai hiljenny hetkeksi nautiskelemaan.
              </p>

              <p>
                Ainesosat: MAITO, sokeri, kaakaovoi, kaakaomassa, emulgointiaine (SOIJAlesitiini), suola, luontainen
                aromi (vanilja). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa
                vähintään 30% kaakaota.
              </p>

              <p>Geisha</p>

              <p>
                Geishan pehmeä maitosuklaa kätkee sisäänsä hasselpähkinänougat-täytteen. Geisha-konvehtien ylellisen
                paksu maitosuklaakuori täydentää rapeaa täytettä. Mikä parasta, tämä herkullinen Geisha-suklaa on
                valmistettu 100 % vastuullisesti tuotetusta kaakaosta ja ilman palmyöljyä. Rauhoita päiväsi hetkeksi ja
                nauti pari Geisha suklaakonvehtia tai tarjoa rakkaillesi hetki yhteistä aikaa niiden parissa.
              </p>

              <p>
                Ainesosat: sokeri, MAITO, kaakaovoi, HASSELPÄHKINÄT (10%), kaakaomassa, täysMAITOjauhe, kirnuMAITOjauhe,
                VEHNÄjauho, maissitärkkelys, emulgointiaine (SOIJAlesitiini), auringonkukkaöljy, suola, aromit. SAATTAA
                SISÄLTÄÄ MUUTA PÄHKINÄÄ, MANTELIA JA MUUTA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Maitosuklaassa vähintään 30%
                kaakaota.
              </p>

              <p>Dumle</p>

              <p>
                Dumle on iloinen klassikko, joka ei aikuistu koskaan. Maitosuklaalla kuorrutettu toffeekaramelli on
                maistuvan riemukas seuralainen, joka saa aina hyvälle tuulelle. Nakkaa suuhun konvehti tai pari!
                Valmistettu 100 % vastuullisesti tuotetusta kaakaosta.
              </p>

              <p>
                Ainesosat: glukoosisiirappi, sokeri, kasvirasva (palmu), täysMAITOjauhe, kaakaovoi, herajauhe (MAIDOSTA),
                kaakaomassa, MAITO, rasvaton MAITOjauhe, suola, emulgointiaine (lesitiini mm. SOIJA), aromit. SAATTAA
                SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA VEHNÄÄ. Maitosuklaassa vähintään 30% kaakaota.
              </p>

              <p>Fazermint</p>

              <p>
                Fazermint on ajattoman elegantti minttukonvehti. Suussa sulavan pehmeä tumma suklaa kietoo sisäänsä
                aidolla piparminttuöljyllä maustettua pehmeää täytettä, joka tarjoaa ylellisyyttä jokaiseen päivään.
                Mikä parasta, kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta
                kaakaosta.
              </p>

              <p>
                Ainesosat: sokeri, kaakaomassa, glukoosisiirappi, kaakaovoi, MAITOrasva, emulgointiaine (SOIJAlesitiini),
                piparminttuöljy, vanilliini, kosteudensäilyttäjä (invertaasi). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA
                GLUTEENIA SISÄLTÄVÄÄ VILJAA. Tummassa suklaassa vähintään 47% kaakaota.
              </p>

              <p>
                Julia
              </p>

              <p>
                Julia on tyylikäs klassikko, joka on maistunut suomalaisille jo 1950-luvulta saakka. Julia-kohvehdissa
                yhdistyy vastustamattomalla tavalla hienostunut tumma suklaa ja samettinen hedelmänmakuinen marmeladi.
                Mikä parasta, kaikki Fazerin herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta
                kaakaosta. Tarjoa ystävillesi elegantti herkutteluhetki Julian seurassa tai nauti itse konvehti tai pari.
              </p>

              <p>
                Ainesosat: sokeri, glukoosisiirappi, vesi, kaakaomassa, kaakaovoi, hyytelöimisaine (E440),
                happamuudensäätöaineet (E330, E331, E337), MAITOrasva, emulgointiaine (SOIJAlesitiini), säilöntäaine
                (E202), aromit. SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA. Tummassa suklaassa
                vähintään 47% kaakaota.
              </p>

              <p>Vihreitä kuulia suklaakonvehti</p>

              <p>
                Kun vihreä klassikkomarmeladi sai ylleen täyteläisen Fazerin tummasuklaakuorrutuksen, syntyi uusi
                suosikkikonvehti, jota kaikki haluavat maistaa. Vastustamattoman ihanat Fazer Vihreitä
                kuulia -suklaakonvehdit maistuvat hurmaavasti raikkaalle päärynälle. Mikä parasta, kaikki Fazerin
                herkulliset suklaat on valmistettu 100 % vastuullisesti tuotetusta kaakaosta. Konvehdit sopivat
                yhteiseen joulun tunnelmointiin tai lahjaksi juhlahetkiin.
              </p>

              <p>
                Ainesosat: sokeri, glukoosisiirappi, vesi, kaakaomassa, kaakaovoi, hyytelöimisaine (E440),
                happamuudensäätöaineet (E330, E331, E337), MAITOrasva, emulgointiaine (SOIJAlesitiini), säilöntäaine
                (E202), aromit, väri (E141). SAATTAA SISÄLTÄÄ PÄHKINÄÄ, MANTELIA JA GLUTEENIA SISÄLTÄVÄÄ VILJAA.
                Tummassa suklaassa vähintään 47% kaakaota.
              </p>

              <button
                class="upload-button button is-primary mt-6"
                @click="$router.push({name: 'Home'})"
              >
                <span>Takaisin</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import { useMeta } from 'vue-meta'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'FAQPage.vue',
  components: {
    SectionHeader,
    Footer
  },
  methods: {
    ...mapActions([
      'getProducts'
    ]),
    ...mapGetters({
      getCalendarMode: 'getCalendarMode'
    }),
  },
  mounted() {
    if (!this.getCalendarMode()) {
      useMeta({
        title: 'Usein kysytyt kysymykset – Custom Fazer',
        description: 'Lue usein kysytyt kysymykset personoituihin pakkauksiin liittyen ja suunnittele oma herkullinen pakettisi.'
      })
    } else {
      useMeta({
        title: 'Usein kysytyt kysymykset – Fazer Oma kalenteri',
        description:
          'Lue usein kysytyt Fazer Oma kalenteriin liittyen ja suunnittele oma herkullinen suklaajoulukalenteri.',
      })
    }
    this.getProducts()
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/mybulma';

h1 {
  color: $primary !important;
  font-size: 45px !important;
}

h3 {
  color: $primary !important;
}

ol {
  counter-reset: item;
  margin-bottom: 2rem;
}

li {
  display: block;
  font-weight: bold;

  li{
    font-weight: normal;
  }
}

li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
  font-weight: bold;
}

.content {
  padding: 20px;
}

button {
  span {
    font-size: 20px;
  }
}

a {
  font-weight: bold;
}
</style>
